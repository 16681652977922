<template>
  <div :id="id" class="overflow-hidden relative flex flex-col min-w-0 break-words bg-white w-full border-b border-blueGray-200">
    <div class="bg-transparent first:rounded-t px-4 py-3">
      <router-link :to="`#${id}`">
        <h5 class="text-blueGray-500 hover:text-blueGray-700 mb-0 font-semibold duration-300 transition-all ease-in-out">
          {{ title }}
          <i v-show="isActive()" class="text-sm mr-2 float-right fas fa-chevron-down duration-300 transition-all ease-in-out transform rotate-180"></i>
          <i v-show="!isActive()" class="text-sm mr-2 float-right fas fa-chevron-down duration-300 transition-all ease-in-out transform"></i>
        </h5>
      </router-link>
    </div>
    <div v-show="isActive()" class="duration-300 transition-all ease-in-out block">
      <div class="text-blueGray-700 px-4 pb-4 flex-auto leading-relaxed">
        <p class="last:mb-2">
          <slot />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    props: {
        id: {
            required: true,
            type: String,
        },
        title: {
            type: String,
            default: "Title",
        }
    },
    setup() {
      const route = useRoute();
      const routeHash = computed(() => {
        return route.hash
      })
      return { routeHash }
    },
    data() {
        return {
            active: false,
        };
    },
    methods: {
        isActive() {
            return (this.routeHash === `#${this.id}`)
        }
    },
};
</script>