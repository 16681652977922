<template>
  <div>
    <navbar />
    <main class="profile-page">
      <section class="relative block h-500-px">
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          />
        </div>
        <div class="container relative mx-auto">
          <div class="w-full lg:w-8/12 px-4 pt-32 ml-auto mr-auto text-center">
            <h3
              class="text-4xl font-semibold leading-normal mb-2 text-white mb-2"
            >
              Frequently Asked Questions
            </h3>
            <p class="text-lg font-semibold text-blueGray-200">
              Below are answers to questions we commonly get from the community.  If you have any other questions or would like a
              question added to this list, please  
              <a
                class="text-lightBlue-300 underline"
                href="mailto:fairwaydrivesafety@gmail.com"
              >
                email us
              </a>.
            </p>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            />
          </svg>
        </div>
      </section>
      <section class="relative py-16 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
          >
            <div class="w-full">
              <accordian id="faq1" title="How did this project start?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  This RTAP process was started in 2019 with a formal request to Supervisor Alcorn's office.  This request was made by a task force member after repeatedly 
                  seeing people running back to the side of the road because of speeding cars, and personally having near misses when crossing Fairway Drive.  Some task 
                  force members and neighbors have had multiple family pets killed by speeders on Fairway Drive.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  FCDOT conducted an eligibility review to determine if Fairway Drive qualified for the program.  There are links to the results below, but the 
                  bottom line is that we qualified for the program.  Once we qualified, the RTAP task force was formed.  All of the members are your neighbors that live on, 
                  or right off of Fairway Drive.  The task force has been working with Supervisor Alcorn's office and FCDOT to develop a traffic calming plan for Fairway Drive.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Study results:
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4">
                  <li>
                    <a
                      class="hyperlink"
                      href="https://chart-studio.plotly.com/~fairwaydrivesafety/1/#/"
                      target="_blank"
                    >
                      See a chart of the study results
                    </a>
                  </li>
                  <li>
                    <a
                      class="hyperlink"
                      href="https://bit.ly/3uYoQFl"
                      target="_blank"
                    >
                      Read the full study
                    </a>
                  </li>
                </ul>
              </accordian>
              <accordian id="faq2" title="Why do drivers speed on Fairway Drive so much?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  The traffic study FCDOT conducted on Fairway Drive in March 2020 showed that <b>91%</b> of eastbound, and <b>84%</b> of westbound vehicles 
                  exceeded the speed limit.  Wny?  Human nature.  It's easy to do it, some times accidentally.  The design of Fairway Drive gives drivers a false 
                  sense of security, leading to speeding. This is because Fairway Drive has...
                </p>
                <ul class="list-disc list-inside text-base font-light leading-relaxed mt-0 mb-4">
                  <li>
                    No centerlines, so it feels wide
                  </li>
                  <li>
                    Few parked cars narrowing the road
                  </li>
                  <li>
                    No crosswalks, so pedestrians aren't expected
                  </li>
                  <li>
                    No stop signs to hinder progress
                  </li>
                </ul>
                <p class="text-base font-light leading-relaxed mt-4 mb-4">
                  We also have the scary left turn from Wiehle with the blind hill (blind summit). People gun it to make the turn and enter Fairway already speeding. 
                  This all adds up to a nice wide road to speed down, with very little to slow you down.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">                
                  To quote from a 
                  <a
                    class="hyperlink"
                    href="https://www.strongtowns.org/journal/2020/7/24/understanding-the-85th-percentile-speed"
                    target="_blank"
                  >
                    2020 Strong Towns article by Charles Marohn
                  </a> 
                  <cite>
                    "If we want drivers to slow down, we don't ignore human behavior.  We change the road design to make it feel less safe for drivers to speed. 
                    In other words: we change the street design to reflect reality." 
                  </cite>
                </p>
                <p class="text-lg font-semibold leading-relaxed mt-0">
                  This is why we need traffic calming measures.
                </p>
              </accordian>
              <h3
                class="text-2xl font-bold leading-normal mx-4 mt-2"
              >
                Traffic Calming
              </h3>
              <accordian id="faq3" title="What is traffic calming?">
                <p class="text-base font-light leading-relaxed mt-0">
                  Traffic calming is the installation of physical devices to lower vehicle speeds (e.g., speed hump, raised median island). 
                  Traffic calming slows speeding traffic on residential streets without restricting access to them.
                </p>
              </accordian>
              <accordian id="faq4" title="Why do we need traffic calming on Fairway Drive?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  The design of Fairway Drive gives drivers a false sense of security, leading to speeding.  This is because Fairway Drive has...
                </p>
                <ul class="list-disc list-inside text-base font-light leading-relaxed mt-0">
                  <li>
                    No centerlines, so it feels wide
                  </li>
                  <li>
                    Few parked cars narrowing the road
                  </li>
                  <li>
                    No crosswalks, so pedestrians aren't expected
                  </li>
                  <li>
                    No stop signs to hinder progress
                  </li>
                </ul>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  We also have the scary left turn from Wiehle with the blind hill (blind summit). People gun it to make the turn and enter Fairway already speeding.
                  All of these factors add up to a nice wide road that is easy to speed down, with very little to slow you down.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Traffic calming is a way to improve the design of residential streets by installing physical devices to slow speeding traffic without restricting access 
                  to the streets.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">                
                  To quote from a 
                  <a
                    class="hyperlink"
                    href="https://www.strongtowns.org/journal/2020/7/24/understanding-the-85th-percentile-speed"
                    target="_blank"
                  >
                    2020 Strong Towns article by Charles Marohn
                  </a> 
                  <cite>
                    "If we want drivers to slow down, we don't ignore human behavior.  We change the road design to make it feel less safe for drivers to speed. 
                    In other words: we change the street design to reflect reality." 
                  </cite>
                </p>
              </accordian>
              <accordian id="faq5" title="Who decided where the traffic calming devices are located?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  FCDOT determined the locations of the traffic calming devices. The locations of the devices are limited by FCDOT's rules about the safety 
                  of the location and effectiveness of the device. In considering the safety of the location they look at things like sight distance to the
                  device, and distance from an intersection, so drivers have enough time to react.  Regarding the effectiveness, the further apart devices 
                  are, the less effective they are due to drivers speeding up between them.  To minimize this, FCDOT mandates a minimum distance between 
                  devices, based on the type of the device.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Below are the rules we were given by FCDOT:
                </p>
                <p class="text-base font-light leading-relaxed mt-0">
                  For Vertical Devices (Speed Hump, Lumps, or Table):
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4 mb-4">
                  <li>Minimum of 300 feet between measures and from existing stop conditions.</li>
                  <li>Minimum of 150 feet from an intersection.</li>
                  <li>Minimum of 150 feet for adequate sight distance.</li>
                </ul>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  For Horizontal Devices (Chokers and Medians):
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4 ">
                  <li>Minimum of 200 feet from the nearest traffic calming measure.</li>
                  <li>Minimum of 150 feet for adequate sight distance.</li>
                </ul>
              </accordian>
              <accordian id="faq-hook-road" title="Why is only part of Fairway Drive being addressed?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  We agree that all of Fairway Drive ultimately needs to be addressed.  Initially Walter Alcorn's office asked us to 
                  address all of Fairway Drive, which we did.  As part of this effort, we discovered that in 2017
                  <a 
                    class="hyperlink"
                    href="https://www.restonnow.com/2018/10/22/new-plan-for-hook-road-recreation-area-revitalization-proposed/"
                    target="_blank"
                  >
                    Reston Association (RA) had developed a plan
                  </a> 
                  to revitalize the Hook Road Recreation Area.  The Hook Road working group had selected a final plan, 
                  which included traffic calming measures.  Traffic calming was identified as their number one priority.  The plan included 
                  bump-outs and a crosswalk on Fairway Drive. Larry Butler, RA COO, was working with us, and attending our meetings, to 
                  coordinate efforts.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  After years of our task force working closely with RA, we were told by the District Supervisor's office that the the 
                  Fairway Drive traffic calming project was being reduced back to its original scope and we would only be addressing from 
                  Wiehle up to the first Hook Road intersection.  As far as we know, RA still intends to implement traffic calming measures 
                  as part of the Hook Road plan.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Given that Reston does not own its roads (VDOT does), the Hook Road plan is still subject to FCDOT and VDOT approval.  
                  We were told in March 2023 that VDOT had rejected the initial traffic calming plan for Hook Road, and that RA is moving on 
                  with the rest of the planned improvements for the recreation area.  RA also said that they intend to submit a new traffic 
                  calming plan to FCDOT and VDOT, but didn't have a schedule for when that would happen.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Here are some links with details about the Hook Road project:
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4 mb-4">
                  <li>
                    <a 
                      class="hyperlink"
                      href="https://www.reston.org/hook-road-project"
                      target="_blank"
                    >
                      Reston Association - Hook Road Recreation Area Project
                    </a>
                  </li>
                  <li>
                    <a 
                      class="hyperlink"
                      href="https://www.ffxnow.com/2023/03/28/pedestrian-walkway-improvements-hook-road-reston/"
                      target="_blank"
                    >
                      FFX Now - Pedestrian walkway improvements and more planned at Hook Road in Reston - March 28, 2023
                    </a>
                  </li>
                  <li>
                    <a 
                      class="hyperlink"
                      href="https://www.restonnow.com/2018/10/22/new-plan-for-hook-road-recreation-area-revitalization-proposed/"
                      target="_blank"
                    >
                      Reston Now - New Plan for Hook Road Recreation Area Revitalization Proposed - October 22, 2018
                    </a>
                  </li>
                  <li>
                    <a 
                      class="hyperlink"
                      href="https://www.restonnow.com/2018/10/26/ra-board-directs-staff-to-budget-50000-for-hook-road-working-group-plan/"
                      target="_blank"
                    >
                      Reston Now - RA Board Directs Staff to Budget $50,000 for Hook Road Working Group Plan - October 26, 2018
                    </a>
                  </li>
                </ul>
              </accordian>
              <accordian id="faq6" title="How will fire and rescue vehicles be affected?">
                <p class="text-base font-light leading-relaxed mt-0">
                  The impact on emergency vehicles is a personal concern for all of task force members, and it was a concern voiced by several people in the 1/15 meeting.  
                  This is why <span class="font-semibold">the speed humps on the current plan will have cut-outs for emergency vehicles to pass through</span>. 
                  The task force also contacted our local firehouse and asked them how speed humps would impact fire trucks, and they said to ask for the cut-outs.  
                </p>
              </accordian>
              <accordian id="faq7" title="How will bicyclists be affected?">
                <p class="text-base font-light leading-relaxed mt-0">
                  The cut-outs in the speed humps for emergency vehicles to pass through also benefit bicyclists, given that they too can pass through them.
                </p>
              </accordian>
              <accordian id="faq8" title="Why not just get the police to ticket speeders?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Implementing traffic calming measures can be an effective solution to address speeding. These measures are often more effective in the long term than 
                  ticketing speeders and are not reliant on the physical presence of police. 
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Please be aware that an increased police presence is not viewed or experienced the same way by everyone in our community. It is important to the task 
                  force to address the speeding issue in a way that promotes safety for all residents while being mindful of potential biases and disparities. It is crucial 
                  to work towards solutions that promote equity and justice in traffic safety initiatives. By involving the community and implementing traffic calming 
                  measures, we can work towards creating a safer environment for everyone using our street. 
                </p>
              </accordian>
              <accordian id="faq9" title="Why can't we just get some stop signs or crosswalks?">
                <p class="text-base font-light leading-relaxed mt-0">
                  Task force members met with VDOT engineers on Fairway Drive to discuss solutions for the intersection at South Shore Road, including stop signs and 
                  crosswalks. Unfortunately VDOT said no to all of their proposals. Stop signs were rejected because there was too much of a difference in the traffic 
                  volume on the two roads.  Crosswalks were rejected due to insufficient sight distance to the crosswalk due to a slight curve at that point on 
                  Fairway Drive. VDOT also said that crosswalks would give pedestrians a false sense of security. VDOT unhelpfully suggested that we should not cross 
                  at this unsafe intersection. 
                </p>
              </accordian>
              <!--accordian id="faq1" title="Why can't we just get some of those &quote;Your Speed&quote; digital signs reminding people how fast they are going?">
                <p class="text-base font-light leading-relaxed mt-0">
                  
                </p>
              </accordian-->
              <accordian id="faq10" title="Can we get a center line painted on the road?  I see people speeding down the middle of the road all the time.">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  A task force member submitted a request to VDOT to paint a center line down the middle of Fairway Drive.  Here is the response from VDOT:
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  <i>
                    "We have completed our review of the request for a centerline on Fairway Drive between Wiehle Avenue and N. Shore Drive. I would like to point out that 
                    some misconceptions exist concerning the use of this pavement marking measure. When painting a centerline on a roadway, it may add to increase in speed
                    on the roadway. Reason being that one now has a guideline to go by that will keep them on their side of the roadway in which will increase speed. 
                    In addition, some on-street parking will have to be restricted therefore our recommendation is not to install a center line on this roadway."
                  </i>
                </p>
              </accordian>
              <accordian id="faq11" title="Can I make changes to the proposed plan or propose a different plan?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Sure.  We are trying to get feedback from the residences in the ballot area on the proposed plan, including changes people want.  One of the primary purposes
                  of the public meeting that will be held by FCDOT is also to get this kind of feedback and possibly make changes needed to get the plan passed by the voters.
                  There are a couple of things to keep in mind when proposing changes.  One, any changes will need to get approval from the affected property owners. We have already
                  gotten their approval for the proposed plan, but that approval was for the specific proposed device.  
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  The second thing to consider is that we are limited to the traffic calming devices FCDOT allows as part of their traffic calming program. FCDOT categorizes its 
                  traffic calming devices as either vertical or horizontal measures. Below are measures that are part of the traffic calming program:
                </p>
                <p class="text-base font-semibold leading-relaxed mt-0 mb-4">
                  Vertical traffic calming devices:
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4 mb-4">
                  <li>Speed hump</li>
                  <li>Speed table</li>
                  <li>Speed cushion</li>
                  <li>Raised crosswalk</li>
                </ul>
                <p class="text-base font-semibold leading-relaxed mt-0 mb-4">
                  Horizontal traffic calming devices:
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4 mb-4">
                  <li>Raised median</li>
                  <li>Choker</li>
                  <li>Bulb-out</li>
                  <li>Chicane</li>
                  <li>Crosswalk refuge</li>
                  <li>Pavement markings (to narrow travel lane)</li>
                </ul>
                <p class="text-base font-light leading-relaxed mt-0">
                  For reference, here is their website:
                  <a
                    class="hyperlink"
                    href="https://www.fairfaxcounty.gov/transportation/residential-traffic-administration"
                    target="_blank"
                  >
                    https://www.fairfaxcounty.gov/transportation/residential-traffic-administration
                  </a>.
                </p>
              </accordian>
              <accordian id="faq12" title="Are there other Reston or surrounding communities that have been through this process?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  These surrounding communities have also been through the RTAP process:
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4 mb-4">
                  <li>Steeplechase Drive (2018), between Lawyers Road & Fox Mill Road</li>
                  <li>Caris Glenne Drive (2016), between Reston Avenue & Shaker Woods Road</li>
                  <li>Golf Course Square, west of Soapstone Drive is currently in the ballot phase - no additional information on this one is available at this time</li>
                </ul>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Here are approvals for some of these from the Board of Supervisors meeting minutes:
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  From: 
                  <a 
                    href="https://www.fairfaxcounty.gov/boardofsupervisors/sites/boardofsupervisors/files/assets/meeting%20materials/board/2017/dec05-board-summary.pdf"
                    class="hyperlink"
                    target="_blank"
                  >
                    Board of Supervisors Meeting Minutes - December 5 2017
                  </a>
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  APPROVED: Three speed humps on Steeplechase Drive (Hunter Mill District) 
                  <a 
                    href="https://maps.app.goo.gl/46jLanWYZYycAn996"
                    class="hyperlink"
                    target="_blank"
                  >
                    [Google Street View]
                  </a>
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  From: 
                  <a 
                    href="https://www.fairfaxcounty.gov/boardofsupervisors/sites/boardofsupervisors/files/assets/meeting%20materials/board/2017/jan24-board-summary.pdf"
                    class="hyperlink"
                    target="_blank"
                  >
                    Board of Supervisors Meeting Minutes - January 24 2017
                  </a>
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  APPROVED:
                  <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4">
                    <li>
                      One speed hump between 11690 and 11691 Caris Glenne Drive (Hunter Mill District) 
                      <a 
                        href="https://maps.app.goo.gl/BTSSTC8FQLn9wqq7A"
                        class="hyperlink"
                        target="_blank"
                      >
                        [Google Street View]
                      </a>
                    </li>
                    <li>
                      One speed hump between 1101 Arboroak Place and 11700 Caris Glenne Drive (Hunter Mill District) 
                      <a 
                        href="https://maps.app.goo.gl/vvBR5CMz9Gmzhm5e6"
                        class="hyperlink"
                        target="_blank"
                      >
                        [Google Street View]
                      </a>
                    </li>
                  </ul>
                </p>
              </accordian>

              <accordian id="faq-property-values" title="Will speed humps reduce my property values?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  There are several studies that have shown that traffic calming measures tend to increase property values.  
                  For example this study, 
                  <a 
                    href="https://www.vtpi.org/calming.pdf"
                    class="hyperlink"
                    target="_blank"
                  >
                    "Traffic Calming Benefits, Costs and Equity Impacts"
                  </a>
                  , done by the Victoria Transport Policy Institute showed that "reduced traffic speed and volumes increase
                  residential property values". They also speculated that the benefits of traffic calming, road safety, 
                  increased comfort and mobility for non-motorized travel, reduced environmental impacts, create more livable
                  communities and contribute to the increased property values.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Other studies have shown unpredictable results on property values, as mentioned in the 
                  <a
                    class="hyperlink"
                    href="https://www.montgomerycountymd.gov/DOT-Traffic/Resources/Files/PDF/SpeedHumps/Fact%20Sheet.pdf"
                    target="_blank"
                  >
                    Speed Hump Fact Sheet
                  </a> from Montgomery County Maryland.
                </p>
              </accordian>
              <h3
                class="text-2xl font-bold leading-normal mx-4 mt-2"
              >
                Voting
              </h3>
              <accordian id="faq-when-vote" title="When will we vote on the traffic calming plan?">
                <p class="text-base font-bold mt-0 mb4">
                  RIGHT NOW! VOTING IS NOW OPEN UNTIL MONDAY, NOVEMBER 20, 2023.
                </p>
                <p class="text-base mt-0 mb4">
                  Ballots were mailed on 10/14 and <span class="font-bold">should start arriving in mailboxes around 10/17</span>. 
                </p>
                <p class="text-base font-bold leading-relaxed mt-0 mb4">
                  <a
                    class="hyperlink"
                    href="#faq-ballot-area"
                  >
                    Are you in the ballot area for this phase?
                  </a>
                </p>
                <!--p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Once FCDOT develops the plan and gets concurrence from the task force, the task force is responsible for reaching out to homeowners that have a device proposed
                  adjacent to their home. Approval from adjacent property owners is required for each device.  Once the task force has obtained the required approvals, FCDOT holds
                  a community meeting to present the plan, explain the program and the ballot process, and solicit feedback from the community. At this meeting residents can ask 
                  questions and provide comments on the plan. If there are changes made based on community feedback at the meeting, another meeting will be called to review the 
                  updated plan. 
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Once the plan is finalized, the community votes to approve the project.  <span class="font-semibold">Please take note of the following:</span>
                </p-->
                <p class="text-base font-bold leading-relaxed mt-0">
                  <a
                    class="hyperlink"
                    href="#faq-vote-pass"
                  >
                    How many votes are needed to pass the plan?
                  </a>
                </p>
              </accordian>
              <accordian 
                id="faq-vote-pass" 
                title="How many votes are needed to pass the traffic calming plan?"
              >
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Once the plan is finalized, the community votes to approve the project.  <span class="font-semibold">Please take note of the following:</span>
                </p>
                <ul class="list-disc list-inside text-base font-semibold leading-relaxed mt-0 mb-4">
                  <li>55 “YES” votes are required for the plan to pass (out of the 109 residences in
                    <a
                      class="hyperlink"
                      href="#faq-ballot-area"
                    >
                      the ballot area
                    </a>
                    ).
                  </li>
                  <li>That's over 50% of all residences, not 50% of the ballots returned.</li>
                  <li>It's a significant hurdle, so we need people to vote.</li>
                  <li class="font-semibold">REMEMBER, if you don't send in your vote it's the same as voting "NO".</li>
                  <li>If you want the plan to succeed, you need to actually send in your ballot.</li>
                </ul>
                <p class="text-base font-semibold leading-relaxed mt-0 mb-4">
                  If the plan doesn't pass, the community will need to wait 2 years, from the date the voting period closed, to try again to get traffic calming.
                </p>
                <p class="text-base font-semibold">It's a significant hurdle...</p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  These RTAP voting requirements set a very high threshold for getting traffic calming passed.  One that even our elected officials aren't required to meet
                  to get into office. By the RTAP rules they would need to get over 50% of all eligible voters to vote for them.  As an example, see the 2019 
                  Board of Supervisors election results:
                </p>
                <table class="table-auto text-base text-blueGray-600">
                  <col class="px-2 py-0">
                  <col class="px-2 py-0">
                  <col class="px-2 py-0">
                  <col class="px-2 py-0">
                  <col class="px-2 py-0">
                  <col class="px-2 py-0">
                  <tr>
                    <td class="border border-blueGray-500 bg-blueGray-500 text-white px-2 py-0 font-bold">Name</td>
                    <td class="border border-blueGray-500 bg-blueGray-500 text-white px-2 py-0 font-bold">District</td>
                    <td class="border border-blueGray-500 bg-blueGray-500 text-white px-2 py-0 font-bold">Votes</td>
                    <td class="border border-blueGray-500 bg-blueGray-500 text-white px-2 py-0 font-bold">Total Votes Cast</td>
                    <td class="border border-blueGray-500 bg-blueGray-500 text-white px-2 py-0 font-bold">Active Reg Voters</td>
                    <td class="border border-blueGray-500 bg-blueGray-500 text-white px-2 py-0 font-bold">% of Reg Voters</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Jeff McKay, Chairman</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">At-Large</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">195,342</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">293,956</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">712,986</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">27%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">James R. Walkinshaw</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Braddock</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">20,480</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">34,032</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">79,564 </td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">26%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">John Foust</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Dranesville</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">23,468</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">35,871</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">81,795 </td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">29%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Walter L. Alcorn</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Hunter Mill</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">30,282</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">32,051</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">87,000 </td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">35%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Rodney L. Lusk</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Lee</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">22,739</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">23,797</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">71,853 </td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">32%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Penelope Gross, Vice Chair</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Mason</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">16,459</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">25,771</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">64,794 </td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">25%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Daniel Storck</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Mount Vernon</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">26,570</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">28,203</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">78,864 </td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">34%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Dalia A. Palchik</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Providence</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">20,901</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">29,845</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">80,466 </td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">26%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Pat Herrity</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Springfield</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">20,389</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">40,422</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">84,852 </td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">24%</td>
                  </tr>
                  <tr>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Kathy Smith</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">Sully</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">22,270</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">35,435</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">83,798</td>
                    <td class="border border-blueGray-500 px-2 py-0 font-light">27%</td>
                  </tr>
                </table>
                <p class="text-sm">
                  Source: 
                  <a 
                    class="hyperlink"
                    href="https://historical.elections.virginia.gov"
                    target="_blank"
                  >
                    Virginia Department of Elections - Historical Elections Database
                  </a>
                </p>
              </accordian>
              <accordian id="faq-ballot-area" title="Who is eligible to vote for the plan?">
                <p class="text-base font-light leading-relaxed mt-0">
                  Please see the
                  <a
                    class="hyperlink"
                    href="docs/Fairway Drive Ballot Map 5.2.2023.pdf"
                    target="_blank"
                  >
                    Ballot Area Map
                  </a> that shows the homes that are included in the ballot area.  See 
                  <a
                    class="hyperlink"
                    href="#faq-ballot-area-how"
                  >
                    How was the ballot area determined?
                  </a>
                </p>
              </accordian>
              <accordian id="faq-ballot-area-how" title="How was the ballot area determined?">
                <p class="text-base font-light leading-relaxed mt-0">
                  FCDOT determined the ballot area for the project. The ballot area comprises residences on the section of Fairway Drive identified for 
                  traffic calming and residences on other streets whose sole or primary access is Fairway Drive, and who would be considerably 
                  inconvenienced if they chose an alternate route.
                </p>
              </accordian>
              <accordian id="faq13" title="Who will manage the voting process?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  FCDOT will provide the ballot materials for the task force to distribute to homes within the ballot area, by mail or in person. 
                  Ballots must be submitted by individual voters directly to the Supervisor's office, who will tally the votes.
                </p>
                <p class="text-base font-light leading-relaxed mt-0">
                  The task force is responsible for arranging all costs associated with the production, distribution, and return postage of the ballots.
                </p>
              </accordian>
              <accordian id="faq14" title="How will the vote be conducted?">
                <p class="text-base font-light leading-relaxed mt-0">
                  The vote will be conducted by balloting.
                </p>
              </accordian>
              <accordian id="faq15" title="Can I vote for just part of the traffic calming plan?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  No.  The RTAP rules state that "The proposed traffic calming plan shall be approved as one integrated plan, i.e., a "YES" vote indicates approval for 
                  all measures in the proposed traffic calming plan; a "NO" vote indicates disapproval for all measures in the proposed traffic calming plan."
                </p>
                <p class="text-base font-semibold leading-relaxed mt-0">
                  Also, if the plan doesn't pass, the community will need to wait 2 years from the date the voting period closed to try again to get traffic calming.
                </p>
              </accordian>
              <h3
                class="text-2xl font-bold leading-normal mx-4 mt-2"
              >
                Affected Property Owners
              </h3>
              <accordian id="faq16" title="As an affected property owner, can I withhold approval on any of the proposed devices?">
                <p class="text-base font-light leading-relaxed mt-0">
                  An affected property owner can only withhold approval for the device adjacent to their property.
                </p>
              </accordian>
              <accordian id="faq17" title="If all affected property owners cannot reach a consensus about a proposed device adjacent to their properties, or whether to have any device at all, what happens?">
                <p class="text-base font-light leading-relaxed mt-0">
                  That device will be relocated or removed from the plan.
                </p>
              </accordian>
              <accordian id="faq-nitsifomp" title="What is a NITSIFOMP?" style="display: none;">
                <dl>
                  <dt class="text-base font-semibold leading-relaxed mt-0 mb-4">
                    <a
                      class="hyperlink"
                      href="https://www.urbandictionary.com/define.php?term=NITSIFOMP"
                      target="_blank"
                    >
                      NITSIFOMP
                    </a>
                  </dt>
                  <dd class="text-base font-light leading-relaxed mt-0 mb-4">
                    Not In The Street In Front Of My Property.
                  </dd>
                  <dd class="text-base font-light leading-relaxed mt-0 mb-4">
                    Similar to 
                    <a
                      class="hyperlink"
                      href="https://www.urbandictionary.com/define.php?term=NIMBY"
                      target="_blank"
                    >
                      NIMBYs (Not In My Backyard)
                    </a>, these property owners wrongfully believe they own the street in front of their property, protesting other people's parked cars and the installation of safety devices.
                  </dd>
                  <dd class="text-base font-light italic leading-relaxed mt-0">
                    The NITSIFOMPs voted against the installation of a speed hump on their street despite the increasing number of pedestrian deaths in their neighborhood.
                  </dd>
                </dl>
              </accordian>
              <h3
                class="text-2xl font-bold leading-normal mx-4 mt-2"
              >
                FCDOT RTAP Process
              </h3>
              <accordian id="faq18" title="How did this project start?">
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  This RTAP process was started in 2019 with a formal request to Supervisor Alcorn's office.  This request was made by a task force member after repeatedly 
                  seeing people running back to the side of the road because of speeding cars, and personally having near misses when crossing Fairway Drive.  Some task 
                  force members and neighbors have had multiple family pets killed by speeders on Fairway Drive.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Eventually FCDOT conducted an eligibility review to determine if Fairway Drive qualified for the program.  There are links to the results below, but the 
                  bottom line is that we qualified for the program.  Once we qualified, the RTAP task force was formed.  All of the members are your neighbors that live on, 
                  or right off of Fairway Drive.  The task force has been working with Supervisor Alcorn's office and FCDOT to develop a traffic calming plan for Fairway Drive.
                </p>
                <p class="text-base font-light leading-relaxed mt-0 mb-4">
                  Study results:
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4">
                  <li>
                    <a
                      class="hyperlink"
                      href="https://chart-studio.plotly.com/~fairwaydrivesafety/1/#/"
                      target="_blank"
                    >
                      See a chart of the study results
                    </a>
                  </li>
                  <li>
                    <a
                      class="hyperlink"
                      href="https://bit.ly/3uYoQFl"
                      target="_blank"
                    >
                      Read the full study
                    </a>
                  </li>
                </ul>
              </accordian>
              <accordian id="faq19" title="Is there a Fairfax County standard operating procedure, agency rules, or other published guidelines that explain this process?">
                <p class="text-base font-light leading-relaxed mt-0">
                  Yes, please see the 
                  <a 
                    class="hyperlink" 
                    href="https://www.fairfaxcounty.gov/transportation/sites/transportation/files/Assets/documents/PDF/Parking/RTAP-General-Operating-Procedures.pdf"
                    target="_blank"
                  >
                    Traffic Calming General Operating Procedures.
                  </a>
                </p>
              </accordian>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import Accordian from "@/components/Accordian/Accordian.vue";

import team2 from "@/assets/img/team-2-800x800.jpg";

export default {
  components: {
    Navbar,
    FooterComponent,
    Accordian,
  },
  data() {
    return {
      team2,
    };
  },
};
</script>
