<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

const schema = yup.object({
  name: yup.string().required(),
  address: yup.string().required(),
  email: yup.string().email().required(),
});

// function onSubmit(values) {
//   alert(JSON.stringify(values, null, 2));
// }
</script>

<template>
  <div>
    <navbar />
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="{ backgroundImage: `url(${banner})` }"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <h1 class="text-white stay-alive uppercase">
                Stay Alive
              </h1>
              <h1 class="text-white font-semibold text-4xl">
                on Fairway Drive
              </h1>
              <p class="mt-4 text-lg text-blueGray-200">
                Join us to make our street safer by reducing speeding on Fairway Drive.  Support the proposed traffic calming measures.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section class="pb-10 bg-blueGray-200 -mt-36 lg:-mt-64">
        <div class="container mx-auto px-4 pt-8">
          <div
            class="relative flex flex-col min-w-0 break-words bg-yellow-50 mb-4 shadow-lg rounded-lg text-yellow-900"
          >
            <div class="px-4 py-3 flex-auto">
              <h6 class="text-xl font-semibold">Current Status</h6>
              <timeline-horizontal class="mt-2" />
              <p class="mt-2 text-lg text-center font-bold">
                07/09/2024 - FAIRWAY DRIVE EAST SPEED HUMPS INSTALLED!
              </p>
              <p class="text-base font-light leading-relaxed mt-0 mb-4">
                The speed humps for the east end of Fairway Drive have been installed.
              </p>
              <p class="mt-2 text-lg text-center font-bold">
                11/21/2023 - FAIRWAY DRIVE EAST TRAFFIC CALMING PLAN PASSES!
              </p>
              <p class="text-base font-light leading-relaxed mt-0 mb-4">
                The traffic calming plan for the eastern half of Fairway Drive was passed on November 21st. We needed 54 "Yes" votes to pass
                and <span class="font-bold">we got 68, which is 87% of the 78 votes received.</span> Passing by this margin, and with a 
                turnout rate of 73.58%, reinforces how much the people in the ballot area agreed there was a problem and that something needed
                to be done about it. 
              </p>
              <p class="text-base font-light leading-relaxed mt-0 mb-4">
                Thank you to the task force members and to everyone that supported the plan. We also want to say a special thank you to the
                affected property owners that agreed to allow devices by their properties.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap space-y-4 md:space-y-0">
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full h-full shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i class="fas fa-award"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Our Mission</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    To reduce the dangerous speeding on Fairway Drive before someone is seriously hurt or killed.
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full h-full shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
                  >
                    <i class="fas fa-question"></i>
                  </div>
                  <h6 class="text-xl font-semibold">What are we doing about it?</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    We have been working with the Fairfax County Department of Transportation (FCDOT) on a plan to install traffic calming 
                    measures to reduce speeding on Fairway Drive.
                  </p>
                  <a
                    class="font-bold uppercase text-blueGray-700 mt-8"
                    href="docs/Current Proposal.pdf"
                    target="_blank"
                  >
                  <button
                      class="w-full bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Current Conceptual Plan
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full h-full shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"
                  >
                    <i class="fas fa-thumbs-up"></i>
                  </div>
                  <h6 class="text-xl font-semibold">We Need You</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Soon you will be asked to vote on the plan to make our road safer.  We need you to support the plan and vote "Yes".  
                    The plan won't happen without your vote.
                  </p>
                  <a
                    class="font-bold uppercase text-blueGray-700 mt-8 flex"
                    href="docs/Fairway Drive Ballot Map 5.2.2023.pdf"
                    target="_blank"
                    style="margin-top: auto;"
                  >
                  <button
                      class="w-full bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Who will need to vote?
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap items-center mt-8">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                We have a speeding problem
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
              >
                In March 2020, FCDOT conducted a traffic study along Fairway Drive.  That study confirmed what many of us see every day.  
                <b><i>We have a dangerous speeding problem on Fairway Drive</i></b>.
              </p>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
              >
                The posted speed limit on Fairway Drive is 25 MPH.  The study showed that...
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fa fa-tachometer-alt"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <b>91%</b> of eastbound (North Shore toward Wiehle), and <b>84%</b> of westbound (Wiehle toward North Shore) vehicles exceeded the speed limit
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fas fa-tachometer-alt"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <b>27%</b> of eastbound vehicles, and <b>19%</b> of westbound vehicles were traveling <b>between 35 and 54 MPH!</b>
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-lg font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fas fa-tachometer-alt"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">1265 vehicles a day traverse Fairway Drive</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </p>
              <!--
              <router-link to="/" class="font-bold text-blueGray-700 mt-8">
                Read the full study
              </router-link>
              -->
              <ul>
                <li>
              <a
                class="font-bold uppercase text-blueGray-700 mt-8"
                href="https://chart-studio.plotly.com/~fairwaydrivesafety/1/#/"
                target="_blank"
              >
                <button
                  class="w-full bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  See a chart of the study results
                </button>
              </a>
            </li>
            <li>
              <a
                class="font-bold uppercase text-blueGray-700 mt-8"
                href="https://bit.ly/3uYoQFl"
                target="_blank"
              >
              <button
                  class="w-full bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Read the full study
                </button>
              </a>
            </li>
            </ul>
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
              >
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1602570123565-eea0732b012a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&dl=janis-fasel-Nn5MQN96Zjs-unsplash.jpg&w=1920"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-emerald-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    We Qualified
                  </h4>
                  <p class="text-md font-light mt-2 text-white">                    
                    Based on FCDOT's eligibility review conducted March 2020, they determined that Fairway Drive met the criteria to qualify 
                    for traffic calming under their Residential Traffic Administration Program (RTAP). The eligibility review included a 
                    traffic count, a speed survey, and an engineering review.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-10">
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="/images/FairwayWhySpeed.jpg"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <h3 class="text-3xl font-semibold">Why speed on Fairway Drive?</h3>
                <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  Human nature.  It's easy to do it.  The design of Fairway Drive gives drivers a false sense of security, leading to speeding.  
                  This is because Fairway Drive has...
                </p>
                <ul class="list-disc text-base font-light leading-relaxed mt-0 ml-4 mb-4">
                  <li class="text-blueGray-500">
                    No centerlines, so it feels wide
                  </li>
                  <li class="text-blueGray-500">
                    Few parked cars narrowing the road
                  </li>
                  <li class="text-blueGray-500">
                    No crosswalks, so pedestrians aren't expected
                  </li>
                  <li class="text-blueGray-500">
                    No stop signs to hinder progress
                  </li>
                </ul>
                <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  We also have the scary left turn from Wiehle with the blind hill (blind summit).
                  People gun it to make the turn and enter Fairway already speeding.
                </p>
                <p class="text-lg font-semibold leading-relaxed mt-4 mb-4 text-blueGray-600">
                  Which all adds up to a nice wide road to speed down, with very little to slow you down.
                </p>
                <cite class="text-lg font-light leading-relaxed">
                  "If we want drivers to slow down, we don't ignore human behavior.  We change the road design to make it feel less safe for drivers to speed. 
                  In other words: we change the street design to reflect reality." 
                  <a
                    class="hyperlink"
                    href="https://www.strongtowns.org/journal/2020/7/24/understanding-the-85th-percentile-speed"
                    target="_blank"
                  >
                    (Marohn, 2020)
                  </a>
                </cite>
                <p class="text-xl font-semibold leading-relaxed mt-4 text-blueGray-600">
                  This is why we need traffic calming measures.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="mailing-list" class="relative block py-10 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-200"
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">
                    Join Our Mailing List
                  </h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Sign up for our mailing list to be notified about upcoming meetings and updates regarding the Fairway Drive traffic calming project.
                  </p>
                  <Form @submit="onSubmit" method="post" :validation-schema="schema" v-slot="{ meta, isSubmitting }">
                    <!--pre>{{ meta }}</pre-->
                    <div v-show="registered" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
                      <span class="text-xl inline-block mr-4 align-middle">
                        <i class="fas fa-bell"></i>
                      </span>
                      <span class="inline-block align-middle mr-8">
                        Thank you for registering.
                      </span>
                      <button class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-4 outline-none focus:outline-none" />
                    </div>
                    <div v-show="error" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                      <span class="text-xl inline-block mr-4 align-middle">
                        <i class="fas fa-exclamation-triangle"></i>
                      </span>
                      <span class="inline-block align-middle mr-8">
                        An error occurred.  Please try again later.
                      </span>
                      <button class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-4 outline-none focus:outline-none" />
                    </div>
                    <input type="hidden" name="form_tools_form_id" value="1" />
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <Field
                        name="name"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                      />
                      <ErrorMessage name="name" class="text-white p-2 border-0 rounded relative bg-red-500"/>
                    </div>

                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="address"
                      >
                        House Number and Street
                      </label>
                      <Field
                        name="address"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="At least the street you live on"
                      />
                      <ErrorMessage name="address" class="text-white p-2 border-0 rounded relative bg-red-500"/>
                    </div>

                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <Field
                        name="email"
                        type="email"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                      <ErrorMessage name="email" class="text-white p-2 border-0 rounded relative bg-red-500"/>
                    </div>

                    <div class="text-center mt-6">
                      <button
                        class="bg-blueGray-800 text-white active:bg-blueGray-600 disabled:bg-gray-200 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        :disabled="!(meta.valid && meta.touched) || isSubmitting"
                        type="submit"
                      >
                        <svg v-if="isSubmitting" aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                        </svg>
                        Subscribe
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
// import axios from 'axios';
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
//import Timeline from "@/components/Timeline/Timeline.vue";
import TimelineHorizontal from "@/components/Timeline/TimelineHorizontal.vue";
import axios from 'axios';

import banner from "@/assets/img/FairwayDr-GoogleMaps-2.png";

export default {
  components: {
    Navbar,
    FooterComponent,
    //TimelineHorizontal,
  },
  data() {
    return {
      banner,
      registered: false,
      error: false,
    };
  },
  methods: {
    onSubmit(values, formActions) {
      const params = new URLSearchParams(values);
      params.append('form_tools_form_id', 1)
      axios.post('/ftools/process.php', params)
          .then((res) => {
            this.registered = true;
            this.error = false;
            formActions.resetForm();
          })
          .catch((error) => {
            this.registered = false;
            this.error = true;
          });
    }
  }
};
</script>
<style lang="css">
.stay-alive {
    font-family: Impact,Haettenschweiler,Franklin Gothic Bold,Charcoal,Helvetica Inserat,Bitstream Vera Sans Bold,Arial Black,sans serif;
    font-size: 4rem; 
    line-height: 1;
} 
</style>
