import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import matomo from 'vue-matomo'

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import Faq from "@/views/Faq.vue";
// import Index from "@/views/Index.vue";

// routes

const routes = [
  {
    path: "/auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/register",
        component: Register,
      },
    ],
  },
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/faq",
    component: Faq,
  },
  {
    path: "/",
    component: Landing,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
      // Go to the top of the page if no hash
      return { x: 0, y: 0 };
    }
  },
  routes,
});

createApp(App)
  .use(router)
  .use(matomo.default, {
    // Configure your matomo server and site by providing
    //host: 'https://matomo.example.com',
    siteId: 55,
    // Enables automatically registering pageviews on the router
    router: router,
  })
  .mount("#app");
